import { LS_NETLIFY_DEPLOY_ID } from '@/const'

export default {
  methods: {
    checkAndUpdateDeployId() {
      if (
        process.env.NODE_ENV === 'production' &&
        process.env.BUILD_ENV === 'production'
      ) {
        fetch(`/getDeployId`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((getBuildIdFromFunction) => {
            if (getBuildIdFromFunction?.deployId) {
              localStorage.setItem(
                LS_NETLIFY_DEPLOY_ID,
                getBuildIdFromFunction.deployId
              )
            } else {
              localStorage.removeItem(LS_NETLIFY_DEPLOY_ID)
            }
          })
          .catch((err) => {
            localStorage.removeItem(LS_NETLIFY_DEPLOY_ID)
            this.$logError.captureException(err)
          })
      }
    },
  },
}
